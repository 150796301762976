a.nostyle:link {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
}

a.nostyle:visited {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
}

.collection-card-uploader-avatar {
  width: 32px;
  height: 32px;
}

.collection-card-clickable {
  cursor: pointer;
}

.collection-card-graph-bg {
  background-color: #eee;
}

.red-heart-color {
  color: red;
  cursor: pointer;
}

.red-heart-color:hover {
  color: #f66;
}

.grey-heart-disabled {
  color: grey;
}

.grey-heart-color {
  color: grey;
  cursor: pointer;
}

.grey-heart-color:hover {
  color: #eee;
}

.muted-filter {
  filter: opacity(20%);
}
