.nav-button {
  background-color: transparent;
  color: #777;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
}

.nav-button:hover {
  background-color: #444;
  color: #fff;
}
