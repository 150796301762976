.dragon-drop {
  cursor: pointer;
  border: dashed 2px rgba(138, 138, 138, 0.7);
  border-radius: 8px;
  padding: 20px;
  text-align: center;
}
.dragon-drop:hover {
  background-color: rgba(138, 138, 138, 0.07);
}

.upload-buttons {
  float: right;
}

.upload-buttons .btn {
  margin-left: 5px;
  margin-right: 5px;
}
