.img-overlay-text {
  color: white;
  text-shadow: 2px 2px 4px #000, 2px 2px 4px #000, 2px 2px 4px #000;
}

.media-play-button {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  color: #fff;
  text-shadow: 2px 2px 4px #000, 2px 2px 4px #000, 2px 2px 4px #000;
}

.svg-shadow {
  -webkit-filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.7));
  /* Similar syntax to box-shadow */
}
